import { useState } from "react";
import { Flex, Box, Link as ChakraLink, Text, Stack, IconButton, Accordion, AccordionItem, AccordionButton, AccordionPanel, Heading } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { FooterData, FooterMenuData, FooterSubMenu } from "@/Types/types";
import { AiFillLinkedin, AiFillFacebook, AiOutlineInstagram } from "react-icons/ai";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { configFile } from "@/config";

interface Props {
  menu: FooterData[];
}

export default function Footer({ menu }: Props) {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <Box
      as="footer"
      backgroundColor={configFile.primaryAccentColor}
      top="0px"
      width="100%"
      position="relative"
    >
      <Flex maxW="1216px" width="95%" m="0 auto" direction="column">
        <Flex
          maxW="1216px"
          width={{ base: "95%", lg: "100%" }}
          m="0 auto"
          flexDirection={{ base: "column-reverse", lg: "row" }}
          pt={{ base: "48px", lg: "48px" }}
          pb={{ base: "48px", lg: "48px" }}
          align="flex-start"
          justifyContent={{ base: "flex-start", lg: "space-between" }}
        >
          <Box mt={{ base: "0", lg: "0" }}>
            <ChakraLink as={Link} href="/">
              <Image src="/images/logo-white.svg" alt="Patinha" width={179} height={52} />
            </ChakraLink>
            <Text
              as="p"
              mt="20px"
              fontSize="16px"
              color="#FFF"
              lineHeight="24px"
              w={{ base: "100%", lg: "385px" }}
            >
              O intuito do Patinha é ser uma plataforma online para conectar amantes de pets em busca de conhecimento confiável, dicas e inspirações sobre saúde animal, comportamento e cuidados gerais.
            </Text>
            <Flex mt="30px" mb={{ base: "32px", lg: "0" }} gap="16px">
              <ChakraLink
                as={Link}
                href="https://www.linkedin.com/company/ecto-digital/"
                target="_blank"
                rel="noopener noreferrer"
                padding="0 !important"
                width="32px"
              >
                <IconButton
                  aria-label="linkedin"
                  icon={
                    <AiFillLinkedin
                      color="#FFF"
                      width="12px"
                    />
                  }
                  bgColor="purple.800"
                  size="sm"
                />
              </ChakraLink>
              <ChakraLink
                as={Link}
                href="https://www.facebook.com/ecto.digital/"
                target="_blank"
                rel="noopener noreferrer"
                padding="0 !important"
                width="32px"
              >
                <IconButton
                  aria-label="facebook"
                  icon={
                    <AiFillFacebook
                      color="#FFF"
                      width="12px"
                    />
                  }
                  bgColor="purple.800"
                  size="sm"
                />
              </ChakraLink>
              <ChakraLink
                as={Link}
                href="https://www.instagram.com/ecto.digital/"
                target="_blank"
                rel="noopener noreferrer"
                padding="0 !important"
                width="32px"
              >
                <IconButton
                  aria-label="instagram"
                  icon={
                    <AiOutlineInstagram
                      color="#FFF"
                      width="12px"
                    />
                  }
                  bgColor="purple.800"
                  size="sm"
                />
              </ChakraLink>
            </Flex>
          </Box>

          {/* Mobile */}
          <Accordion allowToggle w="100%" display={{ base: "block", lg: "none" }} mb="24px">
            <AccordionItem border="none">
              <AccordionButton
                _expanded={{ bg: "transparent" }}
                p="10px 16px"
                w="100%"
                border="1px solid #FFF"
                borderRadius="6px"
                color="#FFF"
                display="flex"
                justifyContent="center"
                gap="8px"
                onClick={() => toggleAccordion(1)}
              >
                Exibir informações
                {expandedIndex === 1 ? (
                  <ChevronUpIcon />
                ) : (
                  <ChevronDownIcon />
                )}
              </AccordionButton>
              <AccordionPanel>
                <Flex
                  align="flex-start"
                  columnGap="100px"
                  as="nav"
                  display="flex"
                  flexDir={{ base: "column", md: "row" }}
                  rowGap="40px"
                >
                  <Flex flexDirection="column" mt="32px" gap="24px">
                    {menu.map((itemMenu: FooterMenuData, indexx: number) => (
                      <Stack key={indexx} minW="176px" mb="16px">
                        <Flex direction="column" gap="24px">
                          <Heading
                            as="h2"
                            color="#FFF"
                            fontWeight={700}
                            fontSize="14px"
                            lineHeight="20px"
                          >
                            {itemMenu.title}
                          </Heading>
                          {itemMenu.items.map(
                            (item: FooterSubMenu, index: number) => (
                              <ChakraLink
                                href={item.path}
                                key={item.id}
                                display="block"
                                as={Link}
                              >
                                <Text
                                  as="h3"
                                  fontWeight="400"
                                  fontSize="14px"
                                  lineHeight="20px"
                                  color="#FFF"
                                >
                                  {item.title}
                                </Text>
                              </ChakraLink>
                            )
                          )}
                        </Flex>
                      </Stack>
                    ))}
                  </Flex>


                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          {/* Desktop */}
          <Flex
            align="flex-start"
            columnGap="100px"
            as="nav"
            display={{ base: "none", lg: "flex" }}
            flexDir={{ base: "column", md: "row" }}
          >
            {menu.map((item: FooterMenuData, index: number) => (
              <Stack key={item.id} minW="176px">
                <Flex direction="column" gap="12px">
                  <Text
                    as="span"
                    color="#FFF !important"
                    fontWeight={600}
                    fontSize="16px"
                    mb="12px"
                  >
                    {item.title}
                  </Text>
                  {item.items.map((i: FooterSubMenu, indexx: number) => (
                    <ChakraLink
                      href={i.path}
                      key={i.id}
                      display="block"
                      as={Link}
                      p="0 !important"
                    >
                      <Text
                        as="h3"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="20px"
                        color="#FFF !important"
                        _hover={{ color: "gray.100", textDecoration: "none" }}
                      >
                        {i.title}
                      </Text>
                    </ChakraLink>
                  ))}
                </Flex>
              </Stack>
            ))}
          </Flex>

        </Flex>
        <Flex
          mb="80px"
          direction={{ base: "column", lg: "row" }}
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px solid #FFF"
          p="10px"
          gap="16px"
        >
          <ChakraLink
            as={Link}
            href="/politica-de-privacidade"
            p="0 !important"
            m="0 !important"
          >
            <Text
              fontSize="14px"
              lineHeight="20px"
              color="#FFF"
              m="auto 0"
            >
              Política de Privacidade
            </Text>
          </ChakraLink>
          <Text
            mb="32px"
            fontSize="14px"
            lineHeight="20px"
            color="#FFF"
            m="auto"
          >
            Copyright {new Date().getFullYear()}. Todos os direitos reservados.
          </Text>
          <ChakraLink
            as={Link}
            href="https://ecto.digital/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="/images/stamp-ectohub.svg"
              alt="made in ectohub"
              width={181}
              height={40}
            />
          </ChakraLink>
        </Flex>
      </Flex>
    </Box>
  );
}
