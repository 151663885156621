import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  IconButton,
  useDisclosure,
  Link as ChakraLink,
  Stack,
  Container,
  Text,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { HeaderMenuData } from "@/Types/types";
import { hasCookie, setCookie } from "cookies-next";
import Search from "@/components/MainPage/Search";
import "instantsearch.css/themes/reset.css";
import "instantsearch.css/themes/satellite.css";
import dynamic from "next/dynamic";
import { configFile } from "@/config";

interface Props {
  menu: HeaderMenuData[];
  submenu: any;
  cookies: any;
}

export default function HeaderMenu({ menu, cookies, submenu }: Props) {
  const { isOpen, onToggle } = useDisclosure();
  const [isCookieBanner, seIsCookieBanner] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<Boolean>();

  const MobileNav = dynamic(() => import("@/components/MobileNav"), {
    ssr: false,
  });
  const DesktopNav = dynamic(() => import("@/components/DesktopNav"), {
    ssr: false,
  });
  const DesktopNavSubmenu = dynamic(() => import("@/components/DesktopNavSubmenu"), {
    ssr: false,
  });

  useEffect(() => {
    if (!hasCookie("notFirstVisit")) {
      seIsCookieBanner(true);
    }

    setIsMobile(window.innerWidth <= 830 ? true : false);

    const cookiesData = async () => {
      cookies.map((item: any) => {
        if (!hasCookie(`${item.attributes.name}`)) {
          const maxAge = () => {
            if (
              item.attributes.duration.days *
              item.attributes.duration.hours *
              item.attributes.duration.minutes ===
              0
            ) {
              return new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
              ).getTime();
            } else {
              return (
                item.attributes.duration.days *
                item.attributes.duration.hours *
                item.attributes.duration.minutes
              );
            }
          };
          setCookie(
            `${item.attributes.name}`,
            `${item.attributes.description}`,
            { path: "/", maxAge: maxAge() }
          );
        }
      });
    };

    cookiesData();
  }, []);

  const closeCookieBanner = () => {
    seIsCookieBanner(false);
    setCookie("notFirstVisit", true, {
      path: "/",
      maxAge: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).getTime(),
    });
  };

  return (
    <>
      {isCookieBanner && (
        <Container as="section" pb="10px" zIndex="999" position="fixed" bottom="0" minWidth="100%">
          <Box
            bg="purple.600"
            color="#F9F9F9"
            p="24px 16px"
            position="relative"
            borderRadius="10px"
            border="1px solid rgba(255, 255, 255, 0.18)"
            margin="0 auto"
            maxW="1216px"
            width={{ lg: '95%' }}
            boxShadow="0px 8px 32px -6px rgba(31, 38, 135, 0.37)"
            backdropFilter="blur(3.75px)"
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="stretch"
              alignItems="center"
              gap={['12px', '80px']}
            >
              <Flex gap="12px" flex={{ base: 'column', lg: 'row' }}>
                <Box position="relative" boxSize="25px" alignSelf="center">
                  <Image
                    alt="cookie simbol"
                    src="/images/cookies.svg"
                    fill
                    style={{ alignSelf: 'center', objectFit: 'cover' }}
                  />
                </Box>
                <Text flex="1 0 0" color="#F9F9F9" fontSize="14px" fontWeight="400" lineHeight="16px">
                  Nós armazenamos temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento. Ver política de privacidade.{' '}
                  <ChakraLink textDecor="underline" as={Link} href="/politica-de-privacidade">
                    Ver política de privacidade.
                  </ChakraLink>
                </Text>
              </Flex>
              <Flex gap="12px">
                <Button
                  aria-label="deny cookies"
                  colorScheme="white"
                  fontSize="sm"
                  lineHeight="5"
                  fontWeight="normal"
                  value="false"
                  onClick={closeCookieBanner}
                >
                  Recusar
                </Button>
                <Button
                  aria-label="accept cookies"
                  p="10px 24px"
                  bgColor="yellow.300"
                  _hover={{ bgColor: "yellow.200" }}
                  borderRadius="9999px"
                  fontSize="18px"
                  lineHeight="28px"
                  fontWeight="600"
                  color="gray.800"
                  onClick={closeCookieBanner}
                  value="true"
                >
                  Aceitar
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}
      <Box
        className="nav-wrapper"
        zIndex="999"
        position="fixed"
        backgroundColor="#fff"
        top="0px"
        width="100%"
      >
        <Flex
          maxW="1216px"
          width="95%"
          m="0 auto"
          pt={{ base: "15px", lg: "16px" }}
          pb={{ base: "15px", lg: "16px" }}
          align="center"
          justifyContent="space-between"
        >
          <Flex align="center" columnGap="84px" pl={{ base: "8px", lg: "0px" }}>
            <Link href="/">
              <Image
                src="/images/logo.svg"
                alt="Patinha"
                width="179"
                height="52"
                priority
              />
            </Link>
            <Box display={{ base: "none", lg: "block" }}>
              <Search />
            </Box>
          </Flex>
          {menu && (
            <>
              {isMobile ? (
                <MobileNav isOpen={isOpen} menu={menu} submenu={submenu} />
              ) : (
                <DesktopNav menu={menu} />
              )}
            </>
          )}
          <IconButton
            onClick={onToggle}
            display={{ lg: "none" }}
            icon={
              isOpen ? (
                <CloseIcon w={3} h={3} />
              ) : (
                <HamburgerIcon w={5} h={5} />
              )
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Box w="100%" bgColor="#FFF" border="1px solid #E2E8F0">
          <DesktopNavSubmenu submenu={submenu} />
        </Box>
      </Box>
    </>
  );
}
